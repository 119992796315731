<template>
  <div>
    <div class="d-flex justify-content-between pb-4 align-items-center">
      <h3 class="fw-600 p-0 m-0">PRODUCTS</h3>
      <div class="card-toolbar">
        <router-link to="/product/basic">
          <b-button v-b-modal.modal-1 variant="dark"><i class="las la-plus"></i> Add New</b-button>
        </router-link>
      </div>
    </div>
    <b-card no-body class="card-custom">


      <!-- User Interface controls -->

      <div class="card-body pt-0 ">
        <DataTable ref="dt" :value="items" dataKey="id" :lazy="true" :paginator="true" :rows="rowCount" class="mt-0"
          :totalRecords="totalRecords" @page="onPage($event)" @sort="onSort($event)" :loading="loading"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products">

          <b-card-header class="py-15px px-0">
            <b-row>
              <b-col lg="2" md="3" sm="6">
                <b-form-group class="m-0">
                  <span class="p-input-icon-left w-100">
                    <i class="pi pi-search" />
                    <b-form-input v-model="filters.name" placeholder="Product Name.." class="form-control py-2"
                      @keydown.enter="onFilter()" />
                  </span>
                </b-form-group>
              </b-col>
              <b-col lg="2" md="3" sm="6">
                <b-form-group class="m-0">
                  <span class="p-input-icon-left w-100">
                    <i class="pi pi-search" />
                    <b-form-input v-model="filters.model" placeholder="Product Model.." class="form-control py-2"
                      @keydown.enter="onFilter()" />
                  </span>
                </b-form-group>
              </b-col>
              <b-col lg="2" md="3" sm="6">
                <b-form-group class="m-0">
                  <span class="p-input-icon-left w-100">
                    <i class="pi pi-search" />

                    <b-form-select v-model="filters.complete_status" placeholder="Completion Status"
                      :options="statusList" @change="onFilter()"></b-form-select>
                  </span>
                </b-form-group>
              </b-col>
              <b-col lg="2" md="3" sm="6">
                <b-form-select v-model="selectedProduct" :options="ProductTypes"></b-form-select>
              </b-col>
              <b-col lg="2" md="3" sm="6">
                <b-form-select v-model="selectedProduct" :options="ProductShape"></b-form-select>
              </b-col>
            </b-row>
          </b-card-header>

          <template #empty>
            <div class="text-center text-muted py-5">Products not found.</div>
          </template>


          <Column field="sl" header="#" class="SL-n">
            <template #body="slotProps">
              {{ slotProps.index + 1 }}
            </template>
          </Column>
          <Column field="Image" header="image" :sortable="true">
            <template #body="slotProps">
              <img v-if="slotProps.data.product_image == null" src="/img/frame-width.svg" alt="..." width="80"
                class="img-fluid img-thumbnail">
              <img v-else :src="imgPath + slotProps.data.product_image" alt="..." width="80"
                class="img-fluid img-thumbnail">
            </template>
          </Column>
          <Column field="name" header="Name" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.name }}
            </template>
          </Column>
          <Column field="model" header="Model" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.model }}
            </template>
          </Column>
          <Column field="type" header="Type" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.product_type }}
            </template>
          </Column>
          <Column field="complete_status" header="Status" ref="complete_status" :sortable="true">
            <template #body="slotProps">
              <div class="text-success" v-if="slotProps.data.complete_status == 'completed'">Complete</div>
              <div class="text-danger" v-else>InComplete</div>
            </template>
          </Column>
          <Column field="action" header="Action" :sortable="true">
            <template #body="slotProps">
              <router-link class="svg-icon svg-icon-md svg-icon-primary" :to="`/product/basic/${slotProps.data.id}`">
                <span class="svg-icon svg-icon-md svg-icon-primary"><svg version="1.1" viewBox="0 0 24 24" height="24px"
                    width="24px" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
                    <g xmlns="http://www.w3.org/2000/svg" id="Stockholm-icons-/-Communication-/-Write" stroke="none"
                      stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                      <path
                        d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                        id="Path-11" fill="#000000" fill-rule="nonzero"
                        transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953) ">
                      </path>
                      <path
                        d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                        id="Path-57" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                    </g>
                  </svg></span>
              </router-link>
            </template>
          </Column>
        </DataTable>
      </div>
    </b-card>
  </div>
</template>

<script>
  import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
  import DataTable from "primevue/datatable";
  import Column from "primevue/column";
  import { FilterMatchMode } from "primevue/api";
  import InputText from 'primevue/inputtext';
  import Dropdown from 'primevue/dropdown';

  import ProductService from "@/core/services/api/product/product";

  export default {
    components: {
      DataTable,
      Column,
      InputText,
      Dropdown
    },
    data() {
      return {
        statusList: [
          { value: null, text: 'Completion Status' },
          { text: "Complete", value: "completed" },
          { text: "InComplete", value: "not" }
        ],
        complete_status: null,
        productList: [],
        imgPath: process.env.VUE_APP_API_STORAGE_URL,
        loading: false,
        totalRecords: 0,
        rowCount: 1,
        lazyParams: {},
        selectedProduct: 'choose',
        ProductTypes: [
          { value: 'choose', text: 'Choose Product Type' },
          { value: 'all', text: 'All' },
          { value: 'mensunglasses', text: 'Men Sunglasses' },
          { value: 'womensunglasses', text: 'Women Sunglasses' },
          { value: 'meneyeglasses', text: 'Men Eyeglasses' },
          { value: 'mensunglasses', text: 'Men Sunglasses', },
        ],
        selectedProductShape: 'choose',
        ProductShape: [
          { value: 'choose', text: 'Choose Product Shape' },
          { value: 'all', text: 'All' },
          { value: 'aviator', text: 'Aviator' },
          { value: 'browline', text: 'Browline' },
          { value: 'cateye', text: 'Cateye' },
          { value: 'geometric', text: 'Geometric', },
          { value: 'ovel', text: 'Ovel', },
          { value: 'rectangle', text: 'Rectangle', },
          { value: 'round', text: 'Round', },
          { value: 'square', text: 'Square', },
          { value: 'wayframe', text: 'Wayframe', },
        ]
      };
    },
    mounted() {
      this.loading = true;

      this.lazyParams = {
        first: 0,
        rows: this.$refs.dt.rows,
        sortField: null,
        sortOrder: null,
        filters: this.filters
      };

      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: "Dashboard",
          route: "/dashboard",
        },
        {
          title: "Products",
        },
      ]);
      this.getProductList();
    },
    computed: {
      filters: function () {
        var filters = {
          name: null,
          model: null,
          complete_status: null,
        }
        return filters
      },
      items: function () {
        var items = this.productList
        return items;
      }
    },
    methods: {
      async getProductList() {
        var response = await ProductService.getProductList(this.lazyParams);
        if (response.data != undefined) {
          this.productList = response.data;
          this.totalRecords = response.total;
          this.rowCount = response.per_page;
        } else {
          this.productList = [];
          this.totalRecords = 0;
          this.rowCount = 1;
        }
        this.loading = false;
      },
      onPage(event) {
        this.lazyParams = event;
        this.getProductList();
      },
      onSort(event) {
        this.lazyParams = event;
        this.getProductList();
      },
      onFilter() {
        this.loading = true;
        this.lazyParams.filters = this.filters;
        this.getProductList();
      },
    },
    watch: {
      // complete_status(newValue) {
      // 	this.filters.complete_status = newValue;
      // 	this.onFilter();
      // }
    }

  };
</script>

<style lang="scss">
  .p-datatable {
    .p-datatable-wrapper {
      .p-datatable-table {
        tr {
          td:first-child {
            width: 30px
          }

          td:last-child {
            width: 100px
          }
        }
      }
    }
  }
</style>